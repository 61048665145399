import {
  Card,
  Typography,
  Form,
  Input,
  Select,
  Button,
  notification,
  Spin,
  Radio,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import API from '../Services/API';
import { useNavigate } from 'react-router-dom';

const AddPricing = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const [pickup, setPickup] = useState('');
  const [destination, setDestination] = useState('');

  const [pickupHelperText, setPickupHelperText] = useState('');
  const [pickupValidationStatus, setPickupValidationStatus] = useState('');

  const [destinationHelperText, setDestinationHelperText] = useState('');
  const [destinationValidationStatus, setDestinationValidationStatus] =
    useState('');

  const [locationsLoading, setLocationsLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [airports, setAirports] = useState([]);
  const [stations, setStations] = useState([]);
  const [disneyHotels, setDisneyHotels] = useState([]);
  const [parisHotelsApartments, setParisHotelsApartments] = useState([]);
  const [otherLocations, setOtherLocations] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setLocationsLoading(true);
      await API.get('/admin/viewlocation').then((res) => {
        if (res.status === 200) {
          const locationsData = res.data;
          setAllLocations(res.data);
          const airportsData = [];
          const stationsData = [];
          const disneyHotelsData = [];
          const parisHotelsApartmentsData = [];
          const otherLocationsData = [];

          for (let index = 0; index < locationsData.length; index++) {
            const location = locationsData[index];
            if (location.Category === 'Airports') {
              let locationObject = {
                label: location.LocationName,
                value: location._id,
              };
              airportsData.push(locationObject);
            } else if (location.Category === 'Train_Stations') {
              let locationObject = {
                label: location.LocationName,
                value: location._id,
              };
              stationsData.push(locationObject);
            } else if (location.Category === 'Disney_Hotels') {
              let locationObject = {
                label: location.LocationName,
                value: location._id,
              };
              disneyHotelsData.push(locationObject);
            } else if (location.Category === 'Paris_Hotels_Apartments') {
              let locationObject = {
                label: location.LocationName,
                value: location._id,
              };
              parisHotelsApartmentsData.push(locationObject);
            } else {
              let locationObject = {
                label: location.LocationName,
                value: location._id,
              };
              otherLocationsData.push(locationObject);
            }
          }

          setAirports(airportsData);
          setStations(stationsData);
          setDisneyHotels(disneyHotelsData);
          setParisHotelsApartments(parisHotelsApartmentsData);
          setOtherLocations(otherLocationsData);
          setLocationsLoading(false);
        }
      });
    } catch (error) {
      setLocationsLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const pickupLocationObj = allLocations.find(
        (location) => location._id === values.pickup
      );

      const destinationLocationObj = allLocations.find(
        (location) => location._id === values.destination
      );

      await API.post('/admin/addprices', {
        Pickup: pickupLocationObj.LocationName,
        Destination: destinationLocationObj.LocationName,
        VehicleType: values.vehicle,
        One_Three_Pack: values.One_Three_Pack,
        One_Four_Pack: values.One_Four_Pack,
        One_Five_Pack: values.One_Five_Pack,
        One_Six_Pack: values.One_Six_Pack,
        One_Seven_Pack: values.One_Seven_Pack,
        One_Eight_Pack: values.One_Eight_Pack,
        Two_Three_Pack: values.Two_Three_Pack,
        Two_Four_Pack: values.Two_Four_Pack,
        Two_Five_Pack: values.Two_Five_Pack,
        Two_Six_Pack: values.Two_Six_Pack,
        Two_Seven_Pack: values.Two_Seven_Pack,
        Two_Eight_Pack: values.Two_Eight_Pack,
      }).then((res) => {
        if (res.status === 201) {
          notification['success']({
            message: 'Success',
            description: res?.data?.message,
          });
          navigate('/prices');
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const handleChangePickup = (value) => {
    setPickup(value);
    if (destination && value === destination) {
      setPickupValidationStatus('error');
      setPickupHelperText('Pickup cannot be same as Destination');
      setDestinationValidationStatus('');
      setDestinationHelperText('');
    } else {
      setPickupValidationStatus('');
      setPickupHelperText('');
      setDestinationValidationStatus('');
      setDestinationHelperText('');
    }
  };

  const handleChangeDestination = (value) => {
    setDestination(value);
    if (pickup && value === pickup) {
      setDestinationValidationStatus('error');
      setDestinationHelperText('Destination cannot be same as Pickup');
      setPickupValidationStatus('');
      setPickupHelperText('');
    } else {
      setDestinationValidationStatus('');
      setDestinationHelperText('');
      setPickupValidationStatus('');
      setPickupHelperText('');
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <Card>
        <Title level={4}>Add Prices</Title>
        <Text type="secondary">
          From here you can select two locations and add prices for that route
        </Text>
      </Card>

      <div className="mt-4">
        <Card>
          {locationsLoading ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : (
            <Form name="pricing_form" onFinish={onFinish}>
              <Row>
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span>Pickup</span>
                  <Form.Item
                    name="pickup"
                    rules={[
                      {
                        required: true,
                        message: 'The Pickup field is required!',
                      },
                    ]}
                    hasFeedback
                    validateStatus={pickupValidationStatus}
                    help={pickupHelperText}
                  >
                    <Select
                      value={pickup}
                      className="w-100"
                      onChange={handleChangePickup}
                      placeholder="Select or Search Pickup Location"
                      showSearch
                      filterOption={filterOption}
                      options={[
                        {
                          label: 'Airports',
                          options: airports,
                        },
                        {
                          label: 'Disneyland & Hotels',
                          options: disneyHotels,
                        },
                        {
                          label: 'Paris Train Stations',
                          options: stations,
                        },
                        {
                          label: 'Paris (Hotels, Apartments..)',
                          options: parisHotelsApartments,
                        },
                        {
                          label: 'Other Location',
                          options: otherLocations,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span>Destination</span>
                  <Form.Item
                    name="destination"
                    rules={[
                      {
                        required: true,
                        message: 'The Destination field is required!',
                      },
                    ]}
                    hasFeedback
                    validateStatus={destinationValidationStatus}
                    help={destinationHelperText}
                  >
                    <Select
                      value={destination}
                      className="w-100"
                      onChange={handleChangeDestination}
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select or Search Destination Location"
                      options={[
                        {
                          label: 'Airports',
                          options: airports,
                        },
                        {
                          label: 'Disneyland & Hotels',
                          options: disneyHotels,
                        },
                        {
                          label: 'Paris Train Stations',
                          options: stations,
                        },
                        {
                          label: 'Paris (Hotels, Apartments..)',
                          options: parisHotelsApartments,
                        },
                        {
                          label: 'Other Location',
                          options: otherLocations,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <span>Vehicle Category</span>
                <Form.Item
                  name="vehicle"
                  rules={[
                    {
                      required: true,
                      message: 'Vehicle Category is required!',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="Car">Car</Radio>
                    <Radio value="Economy Class Van">Economy Class Van</Radio>
                    <Radio value="Business Class Van">Business Class Van</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>

              <div>
                <span>One Way Trip (Single Journey)</span>
                <Row className="mt-3">
                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">1-3 Pax</span>
                    <Form.Item name="One_Three_Pack">
                      <Input
                        placeholder="80"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">4 Pax</span>
                    <Form.Item name="One_Four_Pack">
                      <Input
                        placeholder="90"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">5 Pax</span>
                    <Form.Item name="One_Five_Pack">
                      <Input
                        placeholder="90"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">6 Pax</span>
                    <Form.Item name="One_Six_Pack">
                      <Input
                        placeholder="90"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">7 Pax</span>
                    <Form.Item name="One_Seven_Pack">
                      <Input
                        placeholder="100"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">8 Pax</span>
                    <Form.Item name="One_Eight_Pack">
                      <Input
                        placeholder="100"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div>
                <span>Two Way Trip (Single Journey)</span>
                <Row className="mt-3">
                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">1-3 Pax</span>
                    <Form.Item name="Two_Three_Pack">
                      <Input
                        placeholder="120"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">4 Pax</span>
                    <Form.Item name="Two_Four_Pack">
                      <Input
                        placeholder="130"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">5 Pax</span>
                    <Form.Item name="Two_Five_Pack">
                      <Input
                        placeholder="150"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">6 Pax</span>
                    <Form.Item name="Two_Six_Pack">
                      <Input
                        placeholder="160"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">7 Pax</span>
                    <Form.Item name="Two_Seven_Pack">
                      <Input
                        placeholder="170"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="2" xl="2" lg="2">
                    <span className="small">8 Pax</span>
                    <Form.Item name="Two_Eight_Pack">
                      <Input
                        placeholder="180"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        suffix={process.env.REACT_APP_CURRENCY}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className="col-12"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </div>
    </div>
  );
};

export default AddPricing;
