import { Layout } from 'antd';
import { useSelector } from 'react-redux';

const { Footer } = Layout;

const FooterComponent = () => {
  const { marginLeft } = useSelector((state) => state.layout);

  return (
    <Footer
      style={{
        textAlign: 'center',
        marginLeft: marginLeft,
      }}
    >
      Disney Tour ©{new Date().getFullYear()}
    </Footer>
  );
};

export default FooterComponent;
