import { Card, Typography, Table, Button, notification, Badge } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../Services/API';
import moment from 'moment';

const CompletedBookings = () => {
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState([]);

  useEffect(() => {
    fetchAvailableBookings();
  }, []);

  const fetchAvailableBookings = async () => {
    try {
      setLoading(true);
      await API.get('/admin/viewcompletedebooking').then((res) => {
        if (res.status === 200) {
          setBookingData(res.data);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
    },
    {
      title: 'Pick Up',
      dataIndex: 'pickup',
    },
    {
      title: 'Destination',
      dataIndex: 'Destination',
    },
    {
      title: 'Pickup Date',
      render: (row) => <div>{moment(row.pickupDate).format('YYYY-MM-DD')}</div>,
    },
    {
      title: 'Pickup Time',
      dataIndex: 'pickupTime',
    },
    {
      title: 'Trip Type',
      render: (row) => <div>{row.type === 'One' ? 'One Way' : 'Two Way'}</div>,
    },
    {
      title: 'Status',
      render: (row) => (
        <div className="text-center">
          <Badge count="Complete" color="blue" />
        </div>
      ),
    },
    {
      title: 'Action',
      render: (row) => (
        <Link to={`/booking-details?id=${row._id}&readOnly=${true}`}>
          <Button size="small" type="link">
            See More
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Card>
        <Title level={4}>Completed Bookings</Title>
        <Text type="secondary">
          From here you can see all the completed bookings
        </Text>
      </Card>

      <div className="mt-4">
        <Card>
          <div className="table">
            <div className="table-responsive">
              <Table
                dataSource={bookingData}
                columns={columns}
                loading={loading}
                rowKey="_id"
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CompletedBookings;
