import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sideMenuBroken: false,
  marginLeft: '200px',
  guestView: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    removeMargin: (state) => {
      state.marginLeft = '0px';
    },
    setMargin: (state, action) => {
      state.marginLeft = action.payload;
    },
    storeSideMenuBrokenState: (state, action) => {
      state.sideMenuBroken = action.payload;
    },
  },
});

export const { removeMargin, setMargin, storeSideMenuBrokenState } =
  layoutSlice.actions;

export default layoutSlice.reducer;
