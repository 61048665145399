import {
  Button,
  Card,
  Typography,
  Popconfirm,
  notification,
  Spin,
  Badge,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { DeleteFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import API from '../Services/API';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getLocationType } from '../utils/LocationType';

const BookingDetails = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // eslint-disable-next-line
  const bookingId = queryParams.get('id');
  const readOnly = queryParams.get('readOnly');

  const [bookingData, setBookingData] = useState('');
  const [bookingDataLoading, setBookingDataLoading] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sendMailLoading, setSendMailLoading] = useState(false);

  useEffect(() => {
    if (bookingId) {
      fetchBookingDataById();
    }
    // eslint-disable-next-line
  }, [bookingId]);

  const fetchBookingDataById = async () => {
    try {
      setBookingDataLoading(true);
      await API.get(`/user/viewbooking/${bookingId}`).then((res) => {
        if (res.status === 200) {
          setBookingData(res.data);
          setBookingDataLoading(false);
        }
      });
    } catch (error) {
      setBookingDataLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const confirmDelete = async () => {
    try {
      setDeleteLoading(true);
      await API.delete(`/admin/deletebooking/${bookingId}`).then((res) => {
        if (res.status === 200) {
          setDeleteLoading(false);
          notification['success']({
            message: 'Success',
            description: 'Booking has deleted successfully!',
          });
          navigate('/available-bookings');
        }
      });
    } catch (error) {
      setDeleteLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const cancelDelete = () => {
    // console.log('Click on No');
  };

  const confirmComplete = async () => {
    try {
      setConfirmLoading(true);
      await API.put(`/admin/updatebooking/${bookingId}`, {
        Status: false,
      }).then((res) => {
        if (res.status === 200) {
          setConfirmLoading(false);
          notification['success']({
            message: 'Success',
            description: 'Booking completed successfully!',
          });
          navigate('/available-bookings');
        }
      });
    } catch (error) {
      setConfirmLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const cancelComplete = () => {
    // console.log('Click on No');
  };

  const confirmSendMail = async () => {
    try {
      setSendMailLoading(true);
      await API.post('/user/secondmail', {
        Name: bookingData.Name,
        Destination: bookingData.Destination,
        Email: bookingData.Email,
        type: bookingData.type === 'One' ? 'One Way' : 'Two Way',
        pickupDate: moment(bookingData.pickupDate).format('YYYY-MM-DD'),
        pickupTime: bookingData.pickupTime,
        pickup: bookingData.pickup,
        Price: `${bookingData.Price} ${process.env.REACT_APP_CURRENCY}`,
        Guests: bookingData.Guests,
      }).then((res) => {
        if (res.status === 200) {
          updateConfirmStatus();
        }
      });
    } catch (error) {
      setSendMailLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const updateConfirmStatus = async () => {
    setSendMailLoading(true);
    try {
      await API.put(`admin/updatebookingconfirmation/${bookingData._id}`, {
        isConfirmed: true,
      }).then((res) => {
        if (res.status === 200) {
          notification['success']({
            message: 'Success',
            description: 'Booking confirmation mail sent successfully!',
          });
          navigate('/available-bookings');
        }
      });
    } catch (error) {
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    } finally {
      setSendMailLoading(false);
    }
  };

  const cancelSendMail = () => {
    // console.log('Click on No');
  };

  return (
    <div>
      <Card>
        <Title level={4}>Booking Details</Title>
        <Text type="secondary">Detailed insight of an available booking</Text>
      </Card>

      <div className="mt-4">
        <Card>
          {bookingDataLoading ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-3">
                  <div className="text-secondary fw-bold fst-italic">
                    Ref: {bookingData.Reference}
                  </div>
                  <div>
                    {bookingData.isConfirmed && bookingData.Status && (
                      <Badge count="Confirmed" color="#52c41a" />
                    )}

                    {!bookingData.isConfirmed && bookingData.Status && (
                      <Badge count="Available" color="#faad14" />
                    )}

                    {!bookingData.Status && (
                      <Badge count="Complete" color="blue" />
                    )}
                  </div>
                </div>
                {readOnly === 'false' && (
                  <Popconfirm
                    title="Delete the booking"
                    description="Are you sure to delete this booking?"
                    onConfirm={confirmDelete}
                    onCancel={cancelDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    {deleteLoading ? (
                      <Spin size="small" />
                    ) : (
                      <DeleteFilled
                        style={{
                          fontSize: '20px',
                          color: 'red',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  </Popconfirm>
                )}
              </div>
              <Row>
                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Name:{` `}</Text>
                  <Text>{bookingData.Name}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Email:{` `}</Text>
                  <Text>{bookingData.Email}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Phone:{` `}</Text>
                  <Text>{bookingData.Phonenumber}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">No of Passengers:{` `}</Text>
                  <Text>{bookingData.Guests}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">No of Baby Seats:{` `}</Text>
                  <Text>{bookingData.babyseat}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">No of Baby Boosters:{` `}</Text>
                  <Text>{bookingData.babyboost}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <div className="d-flex gap-3">
                    <Text className="fw-bold">Pickup Location:{` `}</Text>
                    <Text className="d-flex gap-2">
                      {bookingData.pickup}
                      {' -'}
                      {getLocationType(bookingData?.pickupLocationType)}
                    </Text>
                  </div>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <div className="d-flex gap-3">
                    <Text className="fw-bold">Destinatin Location:{` `}</Text>
                    <Text className="d-flex gap-2">
                      {bookingData.Destination}
                      {' -'}
                      {getLocationType(bookingData?.droppingLocationType)}
                    </Text>
                  </div>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Pickup Date:{` `}</Text>
                  <Text>
                    {moment(bookingData.pickupDate).format('YYYY-MM-DD')}
                  </Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Pickup Time:{` `}</Text>
                  <Text>{bookingData.pickupTime}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Trip Type:{` `}</Text>
                  <Text>
                    {bookingData.type === 'One' ? 'One Way' : 'Two Way'}
                  </Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Vehicle Type:{` `}</Text>
                  <Text>{bookingData.vehicleType}</Text>
                </Col>

                <Col
                  xxl="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Price:{` `}</Text>
                  <Text>
                    {bookingData.Price} {process.env.REACT_APP_CURRENCY}
                  </Text>
                </Col>

                {bookingData.flightNumber && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">Flight Number:{` `}</Text>
                    <Text>{bookingData.flightNumber}</Text>
                  </Col>
                )}

                {bookingData.trainNumber && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">Train Number:{` `}</Text>
                    <Text>{bookingData.trainNumber}</Text>
                  </Col>
                )}

                {bookingData.flightOrigin && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">Flight Origin:{` `}</Text>
                    <Text>{bookingData.flightOrigin}</Text>
                  </Col>
                )}

                {bookingData.trainOrigin && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">Train Origin:{` `}</Text>
                    <Text>{bookingData.trainOrigin}</Text>
                  </Col>
                )}

                {bookingData.pickupLocationAddress && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">
                      Pickup Location Address:{` `}
                    </Text>
                    <Text>{bookingData.pickupLocationAddress}</Text>
                  </Col>
                )}

                {bookingData.droppingLocationAddress && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">
                      Destination Location Address:{` `}
                    </Text>
                    <Text>{bookingData.droppingLocationAddress}</Text>
                  </Col>
                )}

                {bookingData.returnDate && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">Return Pickup Date:{` `}</Text>
                    <Text>
                      {moment(bookingData.returnDate).format('YYYY-MM-DD')}
                    </Text>
                  </Col>
                )}

                {bookingData.returnTime && (
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="mt-3"
                  >
                    <Text className="fw-bold">Return Pickup Time:{` `}</Text>
                    <Text>{bookingData.returnTime}</Text>
                  </Col>
                )}

                <Col
                  xxl="12"
                  xl="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  className="mt-3"
                >
                  <Text className="fw-bold">Comments:{` `}</Text>
                  <Text>{bookingData.Comment}</Text>
                </Col>

                {readOnly === 'false' && (
                  <Col
                    xxl="12"
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="mt-4 text-center"
                  >
                    <div className="d-flex justify-content-center gap-2">
                      <div>
                        <Popconfirm
                          title="Confirm the booking"
                          description="Are you sure to confirm this booking?"
                          onConfirm={confirmSendMail}
                          onCancel={cancelSendMail}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="default"
                            loading={sendMailLoading}
                            disabled={bookingData.isConfirmed}
                          >
                            Confirm
                          </Button>
                        </Popconfirm>
                      </div>

                      <div>
                        <Popconfirm
                          title="Complete the booking"
                          description="Are you sure to complete this booking?"
                          onConfirm={confirmComplete}
                          onCancel={cancelComplete}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" loading={confirmLoading}>
                            Complete
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default BookingDetails;
