import { Layout } from 'antd';
import AdminMenuComponent from './AdminMenuComponent';
import Logo from '../../images/logo.png';
import { useDispatch } from 'react-redux';
import {
  removeMargin,
  setMargin,
  storeSideMenuBrokenState,
} from '../../Redux/LayoutSlice';

const { Sider } = Layout;

const SiderComponent = () => {
  const dispatch = useDispatch();

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        if (broken) {
          dispatch(removeMargin());
          dispatch(storeSideMenuBrokenState(true));
        } else {
          dispatch(setMargin('200px'));
          dispatch(storeSideMenuBrokenState(false));
        }
      }}
      style={{
        zIndex: 2,
        position: 'fixed',
        minHeight: '100vh',
        background: '#fff',
      }}
    >
      <div className="p-2" style={{ marginLeft: '20px' }}>
        <img src={Logo} style={{ width: '100px' }} alt="sider-logo" />
      </div>
      <AdminMenuComponent />
    </Sider>
  );
};

export default SiderComponent;
