import {
  Card,
  Typography,
  Table,
  Popconfirm,
  notification,
  Spin,
  Input,
  Button,
} from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import API from '../Services/API';
import Highlighter from 'react-highlight-words';
import { AiOutlineSearch } from 'react-icons/ai';

const ViewPrices = () => {
  const { Title, Text } = Typography;
  const { Column, ColumnGroup } = Table;
  const [loading, setLoading] = useState(false);
  const [pricesData, setPricesData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    try {
      setLoading(true);
      await API.get('/admin/viewprice').then((res) => {
        if (res.status === 200) {
          setPricesData(res.data);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const confirm = async (id) => {
    try {
      setIsDeleting(true);
      await API.delete(`/admin/deleteprice/${id}`).then((res) => {
        if (res.status === 200) {
          setIsDeleting(false);
          notification['success']({
            message: res?.statusText,
            description: res?.data?.status,
          });
          fetchPrices();
        }
      });
    } catch (error) {
      setIsDeleting(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const cancel = () => {
    console.log('Click on No');
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="d-flex gap-3 justify-content-between">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<AiOutlineSearch />}
            size="small"
            className="bg-primary-500"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            size="small"
            className='w-100'
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <AiOutlineSearch style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: <div className='text-nowrap'>Pickup</div>,
      dataIndex: 'Pickup',
      key: 'Pickup',
      render: (row) => <Text className="text-nowrap">{row.Pickup}</Text>,
      ...getColumnSearchProps('Pickup'),
    },
    {
      title: 'Destination',
      dataIndex: 'Destination',
      key: 'Destination',
      render: (row) => <Text className="text-nowrap">{row.Destination}</Text>,
      ...getColumnSearchProps('Destination'),
    },
    {
      title: <div className="text-nowrap">Vehicle Type</div>,
      dataIndex: 'VehicleType',
      key: 'VehicleType',
      render: (row) => <Text className="text-nowrap">{row.VehicleType}</Text>,
      ...getColumnSearchProps('VehicleType'),
    },
    {
      title: 'One Way',
      children: [
        {
          title: <span className="text-nowrap">3 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.One_Three_Pack
                ? `${row.One_Three_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">4 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.One_Four_Pack
                ? `${row.One_Four_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">5 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.One_Five_Pack
                ? `${row.One_Five_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">6 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.One_Six_Pack
                ? `${row.One_Six_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">7 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.One_Seven_Pack
                ? `${row.One_Seven_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">8 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.One_Eight_Pack
                ? `${row.One_Eight_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Two Way',
      children: [
        {
          title: <span className="text-nowrap">3 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.Two_Three_Pack
                ? `${row.Two_Three_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">4 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.Two_Four_Pack
                ? `${row.Two_Four_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">5 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.Two_Five_Pack
                ? `${row.Two_Five_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">6 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.Two_Six_Pack
                ? `${row.Two_Six_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">7 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.Two_Seven_Pack
                ? `${row.Two_Seven_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="text-nowrap">8 Pax</span>,
          key: '_id',
          render: (row) => (
            <Text className="text-nowrap">
              {row.Two_Eight_Pack
                ? `${row.Two_Eight_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Action',
      render: (row) => (
        <Popconfirm
          title="Delete the Price"
          description="Are you sure to delete this price?"
          onConfirm={() => confirm(row._id)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          {isDeleting ? (
            <Spin size="small" />
          ) : (
            <DeleteFilled style={{ color: 'red' }} />
          )}
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Card>
        <Title level={4}>Prices</Title>
        <Text type="secondary">From here you can manage all the prices</Text>
      </Card>

      <div className="mt-4">
        <Card>
          <div className="table">
            <div className="table-responsive">
              <Table
                dataSource={pricesData}
                loading={loading}
                rowKey="_id"
                pagination={{
                  position: ['bottomCenter', 'bottomCenter'],
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                columns={columns}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ViewPrices;
