import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// pages
import Login from './pages/auth/Login';
import NotFound from './pages/auth/NotFound';
import AddLocations from './pages/AddLocations';
import ViewLocations from './pages/ViewLocations';
import AddPricing from './pages/AddPricing';
import ViewPrices from './pages/ViewPrices';
import AvailableBookings from './pages/AvailableBookings';
import BookingDetails from './pages/BookingDetails';
import CompletedBookings from './pages/CompletedBookings';
import NewsletterData from './pages/NewsletterData';
import CreateDiscounts from './pages/CreateDiscounts';
import ViewDiscounts from './pages/ViewDiscounts';

function Pages() {

  const { token } = useSelector((state) => state.auth)
  
  return (
    <Routes>
      <Route path="/login" exact element={<Login />} />
      <Route path="/" exact element={ token ? <Navigate to="/available-bookings" /> : <Navigate to="/login" />} />
      <Route path="/add-locations" exact element={ token ? <AddLocations /> : <Navigate to="/login" />} />
      <Route path="/locations" exact element={ token ? <ViewLocations /> : <Navigate to="/login" />} />
      <Route path="/add-prices" exact element={ token ? <AddPricing /> : <Navigate to="/login" />} />
      <Route path="/prices" exact element={ token ? <ViewPrices /> : <Navigate to="/login" />} />
      <Route path="/available-bookings" exact element={ token ? <AvailableBookings /> : <Navigate to="/login" />} />
      <Route path="/completed-bookings" exact element={ token ? <CompletedBookings /> : <Navigate to="/login" />} />
      <Route path="/booking-details" exact element={ token ? <BookingDetails /> : <Navigate to="/login" />} />
      <Route path="/newsletter-data" exact element={ token ? <NewsletterData /> : <Navigate to="/login" />} />
      <Route path="/discount-create" exact element={ token ? <CreateDiscounts /> : <Navigate to="/login" />} />
      <Route path="/discounts" exact element={ token ? <ViewDiscounts /> : <Navigate to="/login" />} />
      <Route path="*" element={ token ? <NotFound /> : <Navigate to="/login" />} />
    </Routes>
  );
}

export default Pages;
