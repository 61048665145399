import { Layout, Typography, Avatar, Dropdown, Button } from 'antd';
import { SHA256 } from 'crypto-js';
import Identicon from 'identicon.js';
import { DownOutlined } from '@ant-design/icons';
import { logout } from '../../Redux/AuthSlice';
import { useDispatch } from 'react-redux';
import Logo from '../../images/logo.png';

const { Header } = Layout;
const { Title } = Typography;

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const userAcc = 'Admin';

  const generateAvatar = (seed) => {
    const options = {
      size: 80, // Adjust the size of the identicon image
    };
    const hash = SHA256(seed).toString();
    const data = new Identicon(hash.slice(0, 15), options).toString();
    return 'data:image/png;base64,' + data;
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const items = [
    {
      key: '1',
      label: (
        <Button className="w-100" type="ghost" onClick={() => handleLogout()}>
          Logout
        </Button>
      ),
    },
  ];

  return (
    <Header className="fixed-top header-bg" style={{ zIndex: 1 }}>
      <div className="d-flex justify-content-between">
        <div>
          <img
            src={Logo}
            style={{ width: '100px', marginLeft: '-22px', marginTop: '-4px' }}
            alt="sider-logo"
          />
        </div>

        <div className="d-flex">
          <div>
            <Avatar
              size={30}
              style={{ marginRight: '10px' }}
              src={generateAvatar(userAcc)}
            />
          </div>
          <div>
            <Dropdown
              menu={{
                items,
              }}
            >
              {/* eslint-disable-next-line */}
              <a onClick={(e) => e.preventDefault()}>
                <div className="d-flex">
                  <Title level={5} style={{ marginTop: '20px' }}>
                    {userAcc}
                  </Title>
                  <DownOutlined
                    style={{
                      marginTop: '14px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
