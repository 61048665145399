import {
  EnvironmentFilled,
  DollarCircleFilled,
  CarFilled,
  ReadFilled,
  GiftFilled
} from '@ant-design/icons';

// only returns menu items related to user type
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export const items = [
  getItem('Locations', 'sub0', <EnvironmentFilled />, [
    getItem('Create', '/add-locations'),
    getItem('View', '/locations'),
  ]),
  getItem('Prices', 'sub1', <DollarCircleFilled />, [
    getItem('Create', '/add-prices'),
    getItem('View', '/prices'),
  ]),
  getItem('Bookings', 'sub2', <CarFilled />, [
    getItem('Available Bookings', '/available-bookings'),
    getItem('Completed Bookings', '/completed-bookings'),
  ]),
  getItem('Newsletter Data', '/newsletter-data', <ReadFilled />),
  getItem('Discount Codes', 'sub4', <GiftFilled />, [
    getItem('Create', '/discount-create'),
    getItem('View', '/discounts'),
  ]),
];
