import {
  Card,
  Typography,
  Table,
  Popconfirm,
  notification,
  Spin
} from 'antd';
import React, { useState, useEffect } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import API from '../Services/API';
import { getLocationType } from '../utils/LocationType';

const ViewLocations = () => {
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setLoading(true);
      await API.get('/admin/viewlocation').then((res) => {
        if (res.status === 200) {
          setLocationsData(res.data);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const confirm = async (id) => {
    try {
      setIsDeleting(true);
      await API.delete(`/admin/deletelocation/${id}`).then((res) => {
        if (res.status === 200) {
          setIsDeleting(false);
          notification['success']({
            message: res?.statusText,
            description: res?.data?.status,
          });
          fetchLocations();
        }
      });
    } catch (error) {
      setIsDeleting(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const cancel = () => {
    console.log('Click on No');
  };

  const columns = [
    {
      title: 'Location',
      dataIndex: 'LocationName',
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      render: (text) => getLocationType(text),
    },
    {
      title: 'Action',
      render: (row) => (
        <Popconfirm
          title="Delete the location"
          description="Are you sure to delete this locaton?"
          onConfirm={() => confirm(row._id)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          {isDeleting ? (
            <Spin size="small" />
          ) : (
            <DeleteFilled style={{ color: 'red' }} />
          )}
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Card>
        <Title level={4}>Locations</Title>
        <Text type="secondary">From here you can manage all the locations</Text>
      </Card>

      <div className="mt-4">
        <Card>
          <div className="table">
            <div className="table-responsive">
              <Table
                dataSource={locationsData}
                columns={columns}
                loading={loading}
                rowKey="_id"
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ViewLocations;
