import Layout from './LayoutView';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div>
      <Router>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#952020'
            },
          }}
        >
          <Layout />
        </ConfigProvider>
      </Router>
    </div>
  );
}

export default App;
