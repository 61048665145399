import {
  Card,
  Typography,
  Form,
  Button,
  Input,
  Radio,
  notification,
} from 'antd';
import React, { useState } from 'react';
import API from '../Services/API';
import { useNavigate } from 'react-router-dom';

const AddLocations = () => {
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      await API.post('/admin/addlocation', {
        Category: values.category,
        LocationName: values.location,
      }).then((res) => {
        if (res.status === 201) {
          notification['success']({
            message: 'Success',
            description: res?.data?.message,
          });
          navigate('/locations');
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  return (
    <div>
      <Card>
        <Title level={4}>Add a Location</Title>
        <Text type="secondary">
          From here you can add a location under selected category
        </Text>
      </Card>

      <div className="mt-5 col-lg-10 mx-auto">
        <Card>
          <Form name="add_location" onFinish={onFinish}>
            <span className="small">
              Category{' '}
              <span className="text-danger small">
                (* NOTE: Add a location name as 'Other' under any category if
                you want to get a different address from the user.)
              </span>
            </span>
            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: 'Category is required!',
                },
              ]}
            >
              <Radio.Group>
                <Radio value="Airports">Airports</Radio>
                <Radio value="Disney_Hotels">Disneyland & Hotels</Radio>
                <Radio value="Train_Stations">Paris Train Stations</Radio>
                <Radio value="Paris_Hotels_Apartments">
                  Paris (Hotels, Apartments..)
                </Radio>
                <Radio value="Other_Location">Other Location</Radio>
              </Radio.Group>
            </Form.Item>

            <span className="small">Location Name</span>
            <Form.Item
              name="location"
              rules={[
                {
                  required: true,
                  message: 'The Location Name field is required!',
                },
              ]}
            >
              <Input placeholder="Please Enter Location Name Here" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="col-12"
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default AddLocations;
