import { createSlice } from '@reduxjs/toolkit';

const token = localStorage.getItem('taxi_web_logged')
  ? localStorage.getItem('taxi_web_logged')
  : false;

const initialState = {
  token,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = false;
      localStorage.removeItem('taxi_web_logged');
    },
    login: (state) => {
      localStorage.setItem('taxi_web_logged', true);
      state.token = true;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
