import { Card, Typography, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import API from '../Services/API'

const NewsletterData = () => {
  const { Title, Text } = Typography;
  const [newsletterData, setNewsletterData] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNewsletterData()
  }, [])

  const fetchNewsletterData = async () => {
    try {
      setLoading(true);
      await API.get('/admin/fetchmails').then((res) => {
        if(res.status === 200) {
          setLoading(false);
          setNewsletterData(res.data);
        }
      })
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  }


  const columns = [
    {
      title: 'Email',
      dataIndex: 'mail',
    },
  ];

  return (
    <div>
      <Card>
        <Title level={4}>Newsletter Data</Title>
        <Text type="secondary">
          From here you can see all the newsletter subscription data
        </Text>
      </Card>

      <div className="mt-4">
        <Card>
          <div className='table'>
            <div className='table-responsive'>
              <Table dataSource={newsletterData} columns={columns} loading={loading} rowKey="_id" />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewsletterData;
