import { Card, Typography, Table, Popconfirm, notification, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import API from '../Services/API';
import moment from 'moment';

const ViewDiscounts = () => {
  const { Title, Text } = Typography;
  const [discountData, setDiscountData] = useState([])
  const [discountDataLoading, setDiscountDataLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchDiscountData()
  }, [])

  const fetchDiscountData = async () => {
    try {
      setDiscountDataLoading(true);
      await API.get('/admin/viewdiscount').then((res) => {
        if(res.status === 200) {
          setDiscountDataLoading(false);
          setDiscountData(res.data)
        }
      })
    } catch (error) {
      setDiscountDataLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  }

  const confirm = async (id) => {
    try {
      setIsDeleting(true);
      await API.delete(`/admin/deletediscount/${id}`).then((res) => {
        if (res.status === 200) {
          setIsDeleting(false);
          notification['success']({
            message: res?.statusText,
            description: res?.data?.status,
          });
          fetchDiscountData();
        }
      });
    } catch (error) {
      setIsDeleting(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const cancel = () => {
    console.log('Click on No');
  };

  const columns = [
    {
      title: 'Code Name',
      dataIndex: 'CodeName',
    },
    {
      title: 'Off Percentage',
      dataIndex: 'OffPercentage',
      render: (text) => <Text>{text} %</Text>,
    },
    {
      title: 'Expiry Date',
      dataIndex: 'ExpiryDate',
      render: (text) => <Text>{ text ? moment(text).format('YYYY-MM-DD') : ''}</Text>,
    },
    {
      title: 'Expiry Count',
      dataIndex: 'ExpiryCount',
    },
    {
      title: 'Action',
      render: (row) => (
        <Popconfirm
          title="Delete the Promotion"
          description="Are you sure to delete this promotion?"
          onConfirm={() => confirm(row._id)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          {isDeleting ? (
            <Spin size="small" />
          ) : (
            <DeleteFilled style={{ color: 'red' }} />
          )}
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Card>
        <Title level={4}>Discount Codes</Title>
        <Text type="secondary">
          From here you can manage all the discount codes
        </Text>
      </Card>

      <div className="mt-4">
        <Card>
          <div className='table'>
            <div className='table-responsive'>
              <Table dataSource={discountData} columns={columns} rowKey="_id" loading={discountDataLoading} />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ViewDiscounts;
