import { Layout } from 'antd';
import Logo from '../../images/logo.png'

const { Header } = Layout;

const GuestHeader = () => {
  return (
    <Header className="header-bg">
      <img
        src={Logo}
        style={{ width: '120px', marginLeft: '15px' }}
        alt="sider-logo"
      />
    </Header>
  );
};

export default GuestHeader;
