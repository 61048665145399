import { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import Pages from './Pages';
import { useSelector, useDispatch } from 'react-redux';
import { removeMargin, setMargin } from './Redux/LayoutSlice';

// layout components
import SiderComponent from './components/Layout/SiderComponent';
import HeaderComponent from './components/Layout/HeaderComponent';
import GuestHeader from './components/Layout/GuestHeader';
import FooterComponent from './components/Layout/FooterComponent';

const { Content } = Layout;

const LayoutView = () => {
  const { pathname } = useLocation();
  const [guestView, setGuestView] = useState(true);
  const { marginLeft } = useSelector((state) => state.layout);
  const dispatch = useDispatch();

  const width = window.innerWidth;

  // this hook will determine which layout to show
  useEffect(() => {
    if (pathname === '/login') {
      setGuestView(true);
      dispatch(removeMargin());
    } else {
      setGuestView(false);
      if(width > 990) {
        dispatch(setMargin('200px'));
      } else {
        dispatch(removeMargin());
      }
    }
    // eslint-disable-next-line
  }, [pathname, width]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {!guestView && <SiderComponent />}

      <Layout>
        {!guestView && <HeaderComponent />}

        {guestView && <GuestHeader />}

        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <div
            style={{
              marginLeft: marginLeft,
              marginTop: '80px',
            }}
          >
            <Pages />
          </div>
        </Content>

        <FooterComponent />
      </Layout>
    </Layout>
  );
};

export default LayoutView;
