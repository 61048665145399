import {
  Card,
  Typography,
  Table,
  Button,
  notification,
  Badge,
  DatePicker,
  Checkbox,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../Services/API';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const AvailableBookings = () => {
  const { RangePicker } = DatePicker;
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [filteredBookingData, setFilteredBookingData] = useState([]);
  const [isFilterByConfirmed, setIsFilterByConfirmed] = useState(false);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    fetchAvailableBookings();
  }, []);

  useEffect(() => {
    filterData();
  }, [fromDate, toDate, isFilterByConfirmed]);

  const fetchAvailableBookings = async () => {
    try {
      setLoading(true);
      await API.get('/admin/viewavailablebooking').then((res) => {
        if (res.status === 200) {
          setBookingData(res.data);
          setFilteredBookingData(res.data);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
    },
    {
      title: 'Pick Up',
      dataIndex: 'pickup',
    },
    {
      title: 'Destination',
      dataIndex: 'Destination',
    },
    {
      title: 'Pickup Date',
      render: (row) => <div>{moment(row.pickupDate).format('YYYY-MM-DD')}</div>,
    },
    {
      title: 'Pickup Time',
      dataIndex: 'pickupTime',
    },
    {
      title: 'Trip Type',
      render: (row) => <div>{row.type === 'One' ? 'One Way' : 'Two Way'}</div>,
    },
    {
      title: 'Status',
      render: (row) => (
        <div className="text-center">
          {row.isConfirmed ? (
            <Badge count="Confirmed" color="#52c41a" />
          ) : (
            <Badge count="Available" color="#faad14" />
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      render: (row) => (
        <Link to={`/booking-details?id=${row._id}&readOnly=${false}`}>
          <Button size="small" type="link">
            See More
          </Button>
        </Link>
      ),
    },
  ];

  const onChange = (value, dateString) => {
    setFromDate(dateString[0]);
    setToDate(dateString[1]);
  };

  const filterData = () => {
    const data = bookingData;
    const filteredData = data.filter((item) => {
      const pickupDateMoment = moment(item.pickupDate);
      const fromDateMoment = moment(fromDate);
      const toDateMoment = moment(toDate);

      // Check if pickupDate is within the fromDate and toDate range
      const isWithinRange = pickupDateMoment.isBetween(
        fromDateMoment,
        toDateMoment,
        'days',
        '[]'
      );

      if (fromDate && toDate) {
        if (isFilterByConfirmed) {
          // Filter by date range and isConfirmed
          return isWithinRange && item.isConfirmed;
        } else {
          // Filter only by date range
          return isWithinRange;
        }
      } else {
        if (isFilterByConfirmed) {
          // Filter by isConfirmed
          return item.isConfirmed;
        } else {
          // No filters applied
          return true;
        }
      }
    });

    setFilteredBookingData(filteredData);
  };

  const preprocessData = (data) => {
    return data.map((item) => ({
      Date: moment(item.pickupDate).format('YYYY-MM-DD'),
      Booking_Reference: item.Reference,
      Customer_Name: item.Name,
      Pickup_Place: `${item.pickup} ${item.pickupLocationAddress || ''}`,
      Pickup_Time: item.pickupTime,
      Flight: item.flightNumber || 'N/A', // If null, represent as 'N/A'
      Train: item.trainNumber || 'N/A', // If null, represent as 'N/A'
      Drop_Place: `${item.Destination} ${item.droppingLocationAddress || ''}`,
      No_Pax: item.Guests,
      Baby_Seats: item.babyseat,
      Baby_Boosters: item.babyboost,
      Price: `${item.Price} €`,
      Vehicle_Type: item.vehicleType,
      Telephone: item.Phonenumber,
      Email: item.Email,
      Type: item.type === 'One' ? 'One Way' : 'Two Way',
      Return_Date: item.returnDate
        ? moment(item.returnDate).format('YYYY-MM-DD')
        : 'N/A',
      Return_Time: item.returnTime ? item.returnTime : 'N/A',
      Comment: item.Comment || 'N/A',
    }));
  };

  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Preprocess data to match desired structure
    const processedData = preprocessData(filteredBookingData);

    // Creating a workbook and a worksheet
    const ws = XLSX.utils.json_to_sheet([]);
    const wb = XLSX.utils.book_new();

    // Add a title for the date range at the top
    let currentRowIndex = 0;
    if (fromDate || toDate) {
      const title = `Booking Data from ${fromDate || '...'} to ${
        toDate || '...'
      }`;
      XLSX.utils.sheet_add_json(ws, [{ A: title }], {
        skipHeader: true,
        origin: 'A' + (currentRowIndex + 1),
      });
      ws['!merges'] = [
        {
          // Merge cells for title
          s: { r: currentRowIndex, c: 0 }, // start row, start column
          e: {
            r: currentRowIndex,
            c: Object.keys(processedData[0]).length - 1,
          }, // end row, end column
        },
      ];
      currentRowIndex += 2; // Move index two rows down (title + empty row)
    }

    // Adding the processed data to the worksheet (including headers)
    XLSX.utils.sheet_add_json(ws, processedData, {
      skipHeader: false,
      origin: 'A' + (currentRowIndex + 1),
      header: Object.keys(processedData[0]),
    });

    currentRowIndex++; // Move index one row down after headers

    // Adjust column width
    const colWidths = Object.keys(processedData[0]).map((key) =>
      Math.max(
        ...processedData.map((row) => row[key]?.toString().length || 0),
        key.length
      )
    );
    ws['!cols'] = colWidths.map((w) => ({ wch: w + 2 })); // +2 for padding

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Bookings');

    // Writing the workbook and triggering the download
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, Date.now() + fileExtension);
  };

  const exportToPDF = () => {
    // Initialize jsPDF in landscape orientation
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
    });

    // Define the columns and the data mapping
    const tableColumn = [
      'Date',
      'Booking Reference',
      'Customer Name',
      'Pickup Place',
      'Pickup Time',
      'Flight',
      'Train',
      'Drop Place',
      'No Pax',
      'Baby Seats',
      'Baby Boosters',
      'Price',
      'Vehicle Type',
      'Telephone',
      'Email',
      'Type',
      'Return Date',
      'Return Time',
      'Comment',
    ];
    const tableRows = filteredBookingData.map((item) => [
      moment(item.pickupDate).format('YYYY-MM-DD'),
      item.Reference,
      item.Name,
      `${item.pickup} ${
        item.pickupLocationAddress ? item.pickupLocationAddress : ''
      }`,
      item.pickupTime,
      item.flightNumber || 'N/A',
      item.trainNumber || 'N/A',
      `${item.Destination} ${
        item.droppingLocationAddress ? item.droppingLocationAddress : ''
      }`,
      item.Guests,
      item.babyseat,
      item.babyboost,
      `${item.Price} €`,
      item.vehicleType,
      item.Phonenumber,
      item.Email,
      item.type === 'One' ? 'One Way' : 'Two Way',
      item.returnDate ? moment(item.returnDate).format('YYYY-MM-DD') : 'N/A',
      item.returnTime ? item.returnTime : 'N/A',
      item.Comment || 'N/A',
    ]);

    let title = 'Booking Data';
    let titleExists = false; // Flag to check if title exists
    let titleY = 15; // Y-coordinate for the title

    // Add a title for the date range at the top
    if (fromDate && toDate) {
      title = `Booking Data from ${fromDate} to ${toDate}`;
      doc.setFontSize(11); // Smaller font size for the title
      doc.text(title, 14, 15);
      titleExists = true;
    }

    // Starting position for the table and narrow margins
    const margins = { top: 10, right: 10, bottom: 10, left: 10 };
    const startY = titleExists ? titleY + 15 : 10; // Adjust startY based on title existence

    // Add table to pdf
    autoTable(doc, {
      startY: startY,
      margin: margins,
      head: [tableColumn],
      body: tableRows,
      theme: 'grid',
      styles: { overflow: 'linebreak', fontSize: 8, cellPadding: 2 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
        2: { cellWidth: 'auto' },
        // ... (adjust column widths or other styles if needed)
      },
      didDrawPage: function (data) {
        // Set header on each page
        if (data.pageCount > 1) {
          doc.setFontSize(11);
          doc.text(title, 14, 15);
        }
      },
    });

    // Save the PDF
    doc.save(`${Date.now()}.pdf`);
  };

  return (
    <div>
      <Card>
        <div>
          <Title level={4}>Available Bookings</Title>
          <Text type="secondary">
            From here you can manage all the available bookings
          </Text>
        </div>

        <div className="d-flex gap-3 mt-3">
          <div className="flex-grow-1">
            <RangePicker
              className="w-100"
              onChange={onChange}
              placeholder={['Pickup Date From', 'Pickup Date To']}
            />
          </div>

          <div className="my-auto">
            <Checkbox
              onChange={(e) => setIsFilterByConfirmed(e.target.checked)}
            >
              Filter By Confirmed
            </Checkbox>
          </div>

          <div className="d-flex gap-2">
            <Button type="primary" onClick={() => exportToExcel()}>
              Export to Excel
            </Button>

            <Button type="primary" onClick={() => exportToPDF()}>
              Export to PDF
            </Button>
          </div>
        </div>
      </Card>

      <div className="mt-4">
        <Card>
          <div className="table">
            <div className="table-responsive">
              <Table
                dataSource={filteredBookingData}
                columns={columns}
                loading={loading}
                rowKey="_id"
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AvailableBookings;
