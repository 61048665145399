import { BiSolidPlane, BiSolidTrain } from 'react-icons/bi';
import { MdLocationCity } from 'react-icons/md';
import { Tooltip } from 'antd';
import { FaHotel, FaLocationDot } from 'react-icons/fa6';

export const getLocationType = (type) => {
  switch (type) {
    case 'Airports':
      return (
        <Tooltip title="Airport">
          <div className="d-flex gap-1">
            <div className="my-auto">
              <BiSolidPlane />
            </div>

            <div className="my-auto">Airport</div>
          </div>
        </Tooltip>
      );
    case 'Train_Stations':
      return (
        <Tooltip title="Paris Train Stations">
          <div className="d-flex gap-1">
            <div className="my-auto">
              <BiSolidTrain />
            </div>

            <div className="my-auto">Paris Train Stations</div>
          </div>
        </Tooltip>
      );
    case 'Disney_Hotels':
      return (
        <Tooltip title="Disneyland & Hotels">
          <div className="d-flex gap-1">
            <div className="my-auto">
              <MdLocationCity />
            </div>

            <div className="my-auto">Disneyland & Hotels</div>
          </div>
        </Tooltip>
      );
    case 'Paris_Hotels_Apartments':
      return (
        <Tooltip title="Paris (Hotels, Apartments..)">
          <div className="d-flex gap-1">
            <div className="my-auto">
              <FaHotel />
            </div>

            <div className="my-auto">Paris (Hotels, Apartments..)</div>
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Other Location">
          <div className="d-flex gap-1">
            <div className="my-auto">
              <FaLocationDot />
            </div>

            <div className="my-auto">Other Location</div>
          </div>
        </Tooltip>
      );
  }
};
