import {
  Card,
  Typography,
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  notification
} from 'antd';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import API from '../Services/API';
import { useNavigate } from 'react-router-dom';

const CreateDiscounts = () => {
  const { Title, Text } = Typography;
  const [discountType, setDiscountType] = useState('expiry_date');
  const [expiryDate, setExpiryDate] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const handleChangeDiscountType = (value) => {
    setDiscountType(value);
  };

  const onChangeDate = (date, dateString) => {
    setExpiryDate(dateString);
  };

  const onFinish = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      await API.post('/admin/adddiscount', {
        CodeName: values.name,
        OffPercentage: Number(values.percentage),
        DiscountType: discountType,
        ExpiryDate: discountType === 'expiry_date' ? expiryDate : null,
        ExpiryCount: discountType === 'expiry_count' ? Number(values.count) : null
      }).then((res) => {
        if (res.status === 201) {
          notification['success']({
            message: 'Success',
            description: 'Discount created successfully!',
          });
          navigate('/discounts');
        }
        setLoading(false);
      })
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: error?.response?.statusText,
        description: error?.response?.data?.error,
      });
    }
  }

  return (
    <div>
      <Card>
        <Title level={4}>Create Discount</Title>
        <Text type="secondary">
          From here you can create discount codes for passengers
        </Text>
      </Card>

      <div className="mt-5 col-lg-8 mx-auto">
        <Card>
          <Form
            name="create_discount"
            initialValues={{ discount_type: discountType }}
            onFinish={onFinish}
          >
            <span className="small">Code Name</span>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Name is Required!',
                },
              ]}
            >
              <Input placeholder="Please Enter Discount Name" />
            </Form.Item>

            <span className="small">Off Percentage</span>
            <Form.Item
              name="percentage"
              rules={[
                {
                  required: true,
                  message: 'Percentage is Required!',
                },
              ]}
            >
              <Input
                placeholder="Please Enter Discount Off Percentage"
                suffix="%"
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>

            <Row>
              <Col>
                <span className="small">Discount Type</span>
                <Form.Item
                  name="discount_type"
                  rules={[
                    {
                      required: true,
                      message: 'Discount type is required!',
                    },
                  ]}
                >
                  <Select
                    value={discountType}
                    className="w-100"
                    onChange={handleChangeDiscountType}
                    options={[
                      {
                        value: 'expiry_date',
                        label: 'Expiry Date',
                      },
                      {
                        value: 'expiry_count',
                        label: 'Expiry Count',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>

              {discountType === 'expiry_date' && (
                <Col>
                  <span className="small">Expiry Date</span>
                  <Form.Item
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: 'Expiry Date is Required!',
                      },
                    ]}
                  >
                    <DatePicker onChange={onChangeDate} className="w-100" />
                  </Form.Item>
                </Col>
              )}

              {discountType === 'expiry_count' && (
                <Col>
                  <span className="small">Expiry Count</span>
                  <Form.Item
                    name="count"
                    rules={[
                      {
                        required: true,
                        message: 'Expiry Count is Required!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please Enter Expiry Count"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="col-12"
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CreateDiscounts;
