import React, { useState } from 'react';
import { Card, Form, Button, Input, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { login } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const key = 'login';
  const [loading, setLoading] = useState(false);
  const password = '123456';

  const onFinish = (values) => {
    setLoading(true);
    if (values.password === password) {
      dispatch(login());
      notification['success']({
        key,
        message: 'Success',
        description: 'Welcome!',
      });
      setLoading(false);
      navigate('/available-bookings');
    } else {
      notification['error']({
        key,
        message: 'Sorry',
        description: 'The password you have entered is wrong!',
      });
      setLoading(false);
    }
  };

  return (
    <div className="col-lg-6 col-md-8 col-sm-12 mx-auto mt-5">
      <Card hoverable>
        <div className="mb-4">
          <h5>Welcome to Disney Tour Website 👋</h5>
          <span className="text-muted">Please login to your account</span>
        </div>

        <Form name="login" onFinish={onFinish} autoComplete="off">
          <span className="small">Pin</span>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Pin is required!',
              },
            ]}
          >
            <Input.Password
              placeholder="Please enter the login pin code"
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="col-12"
              loading={loading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
