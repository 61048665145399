import axios from 'axios';

// this is the API used to handle user logins and registrations
const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  // add static headers from here
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

export default API;

export const AXIOS = axios;
